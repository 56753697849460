<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4"></div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Tạo dự án mới</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <base-alert type="success" v-if="submitSuccess">
            <strong>Thêm dự án thành công!</strong>
          </base-alert>
          <!-- Form groups used in grid -->
          <div class="row">
            <div class="col-md-12">
              <base-input
                v-model="inputs.name"
                :inputClasses="errors.get('name') ? 'is-invalid' : ''"
                label="Tên dự án"
              >
              </base-input>
              <div
                v-if="errors.get('name')"
                class="invalid-feedback"
                :style="{ display: errors.get('name') ? 'block' : 'none' }"
              >
                {{ errors.get("name") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input
                v-model="inputs.url"
                :inputClasses="errors.get('url') ? 'is-invalid' : ''"
                label="URL"
              >
              </base-input>
              <div
                v-if="errors.get('url')"
                class="invalid-feedback"
                :style="{ display: errors.get('url') ? 'block' : 'none' }"
              >
                {{ errors.get("url") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Từ khóa">
                <textarea
                  v-model="inputs.keyword"
                  :class="[
                    errors.get('keyword') ? 'is-invalid' : '',
                    'form-control',
                  ]"
                  rows="7"
                ></textarea>
              </base-input>
              <div
                v-if="errors.get('keyword')"
                class="invalid-feedback"
                :style="{ display: errors.get('keyword') ? 'block' : 'none' }"
              >
                {{ errors.get("keyword") }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input
                label="Vị trí"
                :inputClasses="errors.get('location_name') ? 'is-invalid' : ''"
              >
                <el-select
                  v-model="inputs.location_name"
                  filterable
                  remote
                  :remote-method="remoteLocationFilter"
                  :loading="loadingLocations"
                  placeholder="Vietnam"
                >
                  <el-option
                    v-for="option in locationNameOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <div
                  v-if="errors.get('location_name')"
                  class="invalid-feedback"
                  :style="{
                    display: errors.get('location_name') ? 'block' : 'none',
                  }"
                >
                  {{ errors.get("location_name") }}
                </div>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Ngôn ngữ">
                <el-select
                  v-model="inputs.language_name"
                  filterable
                  placeholder="Vietnamese"
                >
                  <el-option
                    v-for="option in languageNameOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <div
                  v-if="errors.get('language_name')"
                  class="invalid-feedback"
                  :style="{
                    display: errors.get('language_name') ? 'block' : 'none',
                  }"
                >
                  {{ errors.get("language_name") }}
                </div>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <base-input label="Công cụ tìm kiếm(không bắt buộc)">
                <el-select
                  v-model="inputs.se_domain"
                  filterable
                  placeholder="www.google.com"
                >
                  <el-option
                    v-for="option in seDomainOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input label="Thiết bị(không bắt buộc)">
                <el-select
                  v-model="inputs.device"
                  filterable
                  placeholder="desktop"
                >
                  <el-option
                    v-for="option in deviceOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-md-6">
              <base-input label="Hệ điều hành(không bắt buộc)">
                <el-select
                  v-model="inputs.os"
                  filterable
                  placeholder="Chọn hệ điều hành"
                >
                  <el-option
                    v-for="option in osOptionsDefault"
                    :key="option.label"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <base-button
            type="primary"
            native-type="submit"
            @click.prevent="onSubmit"
            :loading="isLoading"
            >Submit</base-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import _ from "lodash";
import Errors from "@/utils/error";
import se_domains from "@/utils/se_domains";

export default {
  name: "AddTerm",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      inputs: {
        name: "",
        keyword: "",
        url: "",
        location_name: "Vietnam",
        language_name: "Vietnamese",
        se_domain: "",
        device: "",
        os: "",
      },
      errors: new Errors(),
      errText: "",
      submitSuccess: false,
      isLoading: false,
      locationNameOptions: [],
      languageNameOptions: [],
      deviceOptions: [
        { label: "Desktop", value: "desktop" },
        { label: "Mobile", value: "mobile" },
      ],
      osOptionsDefault: [],
      osOptions: {
        desktop: [
          { label: "windows", value: "windows" },
          { label: "macos", value: "macos" },
        ],
        mobile: [
          { label: "android", value: "android" },
          { label: "ios", value: "ios" },
        ],
      },
      seDomainOptions: se_domains,
      loadingLocations: false,
    };
  },
  created() {
    this.loadLanguages();
  },
  watch: {
    "inputs.device": function (device) {
      this.inputs.os = "";
      this.osOptionsDefault = this.osOptions[device];
    },
  },
  computed: {
    hasErrors() {
      return !_.isEmpty(this.errors.errors);
    },
  },
  methods: {
    resetForm() {
      this.inputs = {
        name: "",
        keyword: "",
        url: "",
        location_name: "Vietnam",
        language_name: "Vietnamese",
        se_domain: "",
        device: "",
        os: "",
      };
    },
    onSubmit() {
      let self = this;
      this.submitSuccess = false;
      this.errors = new Errors();
      this.errText = "";
      this.isLoading = true;

      this.$store
        .dispatch("term/addProject", self.inputs)
        .then(() => {
          this.resetForm();
          this.submitSuccess = true;
          this.isLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            self.errors.record(err.response.data);
          }

          if (err.response.status === 503) {
            self.errText = "Tạo dự án không thành công xin vui lòng thử lại!";
          } else {
            self.errText = "Thông tin nhập vào không đúng, xin thử lại!";
          }

          self.isLoading = false;
        });
    },
    async loadLanguages() {
      const languages = await this.$store.dispatch("term/getLanguages");

      this.languageNameOptions = _.map(languages, function (language) {
        return { label: language.name, value: language.name };
      });
    },
    async remoteLocationFilter(query) {
      if (query !== "") {
        this.loadingLocations = true;

        const locations = await this.$store.dispatch(
          "term/getLocations",
          query
        );

        this.loadingLocations = false;
        this.locationNameOptions = _.map(locations, function (location) {
          return { label: location.name, value: location.name };
        });
      } else {
        this.locationNameOptions = [];
      }
    },
  },
};
</script>

<style scoped></style>
