import { render, staticRenderFns } from "./AddTermProject.vue?vue&type=template&id=64d07356&scoped=true&"
import script from "./AddTermProject.vue?vue&type=script&lang=js&"
export * from "./AddTermProject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d07356",
  null
  
)

export default component.exports