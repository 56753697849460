var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('div',{staticClass:"row align-items-center py-4"})]),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card mb-4"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(_vm.errText !== '')?_c('base-alert',{attrs:{"type":"danger"}},[_c('strong',[_vm._v("Lỗi!")]),_vm._v(" "+_vm._s(_vm.errText)+" ")]):_vm._e(),(_vm.submitSuccess)?_c('base-alert',{attrs:{"type":"success"}},[_c('strong',[_vm._v("Thêm dự án thành công!")])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"inputClasses":_vm.errors.get('name') ? 'is-invalid' : '',"label":"Tên dự án"},model:{value:(_vm.inputs.name),callback:function ($$v) {_vm.$set(_vm.inputs, "name", $$v)},expression:"inputs.name"}}),(_vm.errors.get('name'))?_c('div',{staticClass:"invalid-feedback",style:({ display: _vm.errors.get('name') ? 'block' : 'none' })},[_vm._v(" "+_vm._s(_vm.errors.get("name"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"inputClasses":_vm.errors.get('url') ? 'is-invalid' : '',"label":"URL"},model:{value:(_vm.inputs.url),callback:function ($$v) {_vm.$set(_vm.inputs, "url", $$v)},expression:"inputs.url"}}),(_vm.errors.get('url'))?_c('div',{staticClass:"invalid-feedback",style:({ display: _vm.errors.get('url') ? 'block' : 'none' })},[_vm._v(" "+_vm._s(_vm.errors.get("url"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"label":"Từ khóa"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.keyword),expression:"inputs.keyword"}],class:[
                  _vm.errors.get('keyword') ? 'is-invalid' : '',
                  'form-control',
                ],attrs:{"rows":"7"},domProps:{"value":(_vm.inputs.keyword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputs, "keyword", $event.target.value)}}})]),(_vm.errors.get('keyword'))?_c('div',{staticClass:"invalid-feedback",style:({ display: _vm.errors.get('keyword') ? 'block' : 'none' })},[_vm._v(" "+_vm._s(_vm.errors.get("keyword"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('base-input',{attrs:{"label":"Vị trí","inputClasses":_vm.errors.get('location_name') ? 'is-invalid' : ''}},[_c('el-select',{attrs:{"filterable":"","remote":"","remote-method":_vm.remoteLocationFilter,"loading":_vm.loadingLocations,"placeholder":"Vietnam"},model:{value:(_vm.inputs.location_name),callback:function ($$v) {_vm.$set(_vm.inputs, "location_name", $$v)},expression:"inputs.location_name"}},_vm._l((_vm.locationNameOptions),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1),(_vm.errors.get('location_name'))?_c('div',{staticClass:"invalid-feedback",style:({
                  display: _vm.errors.get('location_name') ? 'block' : 'none',
                })},[_vm._v(" "+_vm._s(_vm.errors.get("location_name"))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-md-6"},[_c('base-input',{attrs:{"label":"Ngôn ngữ"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Vietnamese"},model:{value:(_vm.inputs.language_name),callback:function ($$v) {_vm.$set(_vm.inputs, "language_name", $$v)},expression:"inputs.language_name"}},_vm._l((_vm.languageNameOptions),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1),(_vm.errors.get('language_name'))?_c('div',{staticClass:"invalid-feedback",style:({
                  display: _vm.errors.get('language_name') ? 'block' : 'none',
                })},[_vm._v(" "+_vm._s(_vm.errors.get("language_name"))+" ")]):_vm._e()],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"label":"Công cụ tìm kiếm(không bắt buộc)"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"www.google.com"},model:{value:(_vm.inputs.se_domain),callback:function ($$v) {_vm.$set(_vm.inputs, "se_domain", $$v)},expression:"inputs.se_domain"}},_vm._l((_vm.seDomainOptions),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('base-input',{attrs:{"label":"Thiết bị(không bắt buộc)"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"desktop"},model:{value:(_vm.inputs.device),callback:function ($$v) {_vm.$set(_vm.inputs, "device", $$v)},expression:"inputs.device"}},_vm._l((_vm.deviceOptions),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1),_c('div',{staticClass:"col-md-6"},[_c('base-input',{attrs:{"label":"Hệ điều hành(không bắt buộc)"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Chọn hệ điều hành"},model:{value:(_vm.inputs.os),callback:function ($$v) {_vm.$set(_vm.inputs, "os", $$v)},expression:"inputs.os"}},_vm._l((_vm.osOptionsDefault),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1)]),_c('base-button',{attrs:{"type":"primary","native-type":"submit","loading":_vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v("Submit")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Tạo dự án mới")])])
}]

export { render, staticRenderFns }