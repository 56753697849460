const se_domains = [
  {label: 'Global / US (www.google.com)', value: "www.google.com"},
  {label: 'Afghanistan (www.google.com.af)', value: "www.google.com.af"},
  {label: 'Albania (www.google.al)', value: "www.google.al"},
  {label: 'Algeria (www.google.dz)', value: "www.google.dz"},
  {label: 'American Samoa (www.google.as)', value: "www.google.as"},
  {label: 'Andorra (www.google.ad)', value: "www.google.ad"},
  {label: 'Angola (www.google.co.ao)', value: "www.google.co.ao"},
  {label: 'Anguilla (www.google.com.ai)', value: "www.google.com.ai"},
  {label: 'Antigua and Barbuda (www.google.com.ag)', value: "www.google.com.ag"},
  {label: 'Argentina (www.google.com.ar)', value: "www.google.com.ar"},
  {label: 'Armenia (www.google.am)', value: "www.google.am"},
  {label: 'Ascension Island (www.google.ac)', value: "www.google.ac"},
  {label: 'Australia (www.google.com.au)', value: "www.google.com.au"},
  {label: 'Austria (www.google.at)', value: "www.google.at"},
  {label: 'Azerbaijan (www.google.az)', value: "www.google.az"},
  {label: 'Bahamas (www.google.bs)', value: "www.google.bs"},
  {label: 'Bahrain (www.google.com.bh)', value: "www.google.com.bh"},
  {label: 'Bangladesh (www.google.com.bd)', value: "www.google.com.bd"},
  {label: 'Belarus (www.google.by)', value: "www.google.by"},
  {label: 'Belgium (www.google.be)', value: "www.google.be"},
  {label: 'Belize (www.google.com.bz)', value: "www.google.com.bz"},
  {label: 'Benin (www.google.bj)', value: "www.google.bj"},
  {label: 'Bhutan (www.google.bt)', value: "www.google.bt"},
  {label: 'Bolivia (www.google.com.bo)', value: "www.google.com.bo"},
  {label: 'Bosnia Herzegovina (www.google.ba)', value: "www.google.ba"},
  {label: 'Botswana (www.google.co.bw)', value: "www.google.co.bw"},
  {label: 'Brasil (www.google.com.br)', value: "www.google.com.br"},
  {label: 'British Virgin Islands (www.google.vg)', value: "www.google.vg"},
  {label: 'Brunei (www.google.com.bn)', value: "www.google.com.bn"},
  {label: 'Bulgaria (www.google.bg)', value: "www.google.bg"},
  {label: 'Burkina Faso (www.google.bf)', value: "www.google.bf"},
  {label: 'Burma (www.google.com.mm)', value: "www.google.com.mm"},
  {label: 'Burundi (www.google.bi)', value: "www.google.bi"},
  {label: 'Cambodia (www.google.com.kh)', value: "www.google.com.kh"},
  {label: 'Cameroon (www.google.cm)', value: "www.google.cm"},
  {label: 'Canada (www.google.ca)', value: "www.google.ca"},
  {label: 'Cape Verde (www.google.cv)', value: "www.google.cv"},
  {label: 'Catalan Countries (www.google.cat)', value: "www.google.cat"},
  {label: 'Central African Republic (www.google.cf)', value: "www.google.cf"},
  {label: 'Chad (www.google.td)', value: "www.google.td"},
  {label: 'Chile (www.google.cl)', value: "www.google.cl"},
  {label: 'Colombia (www.google.com.co)', value: "www.google.com.co"},
  {label: 'Cook Islands (www.google.co.ck)', value: "www.google.co.ck"},
  {label: 'Costa Rica (www.google.co.cr)', value: "www.google.co.cr"},
  {label: 'Croatia (www.google.hr)', value: "www.google.hr"},
  {label: 'Cuba (www.google.com.cu)', value: "www.google.com.cu"},
  {label: 'Cyprus (www.google.com.cy)', value: "www.google.com.cy"},
  {label: 'Czech (www.google.cz)', value: "www.google.cz"},
  {label: 'Democratic Republic of the Congo (www.google.cd)', value: "www.google.cd"},
  {label: 'Denmark (www.google.dk)', value: "www.google.dk"},
  {label: 'Djibouti (www.google.dj)', value: "www.google.dj"},
  {label: 'Dominica (www.google.dm)', value: "www.google.dm"},
  {label: 'Dominican Republic (www.google.com.do)', value: "www.google.com.do"},
  {label: 'Ecuador (www.google.com.ec)', value: "www.google.com.ec"},
  {label: 'Egypt (www.google.com.eg)', value: "www.google.com.eg"},
  {label: 'El Salvador (www.google.com.sv)', value: "www.google.com.sv"},
  {label: 'Estonia (www.google.ee)', value: "www.google.ee"},
  {label: 'Ethiopia (www.google.com.et)', value: "www.google.com.et"},
  {label: 'Federated States of Micronesia (www.google.fm)', value: "www.google.fm"},
  {label: 'Fiji (www.google.com.fj)', value: "www.google.com.fj"},
  {label: 'Finland (www.google.fi)', value: "www.google.fi"},
  {label: 'France (www.google.fr)', value: "www.google.fr"},
  {label: 'Gabon (www.google.ga)', value: "www.google.ga"},
  {label: 'Gambia (www.google.gm)', value: "www.google.gm"},
  {label: 'Georgia (www.google.ge)', value: "www.google.ge"},
  {label: 'Germany (www.google.de)', value: "www.google.de"},
  {label: 'Ghana (www.google.com.gh)', value: "www.google.com.gh"},
  {label: 'Gibraltar (www.google.com.gi)', value: "www.google.com.gi"},
  {label: 'Greece (www.google.gr)', value: "www.google.gr"},
  {label: 'Greenland (www.google.gl)', value: "www.google.gl"},
  {label: 'Guadeloupe (www.google.gp)', value: "www.google.gp"},
  {label: 'Guatemala (www.google.com.gt)', value: "www.google.com.gt"},
  {label: 'Guernsey (www.google.gg)', value: "www.google.gg"},
  {label: 'Guyana (www.google.gy)', value: "www.google.gy"},
  {label: 'Haiti (www.google.ht)', value: "www.google.ht"},
  {label: 'Honduras (www.google.hn)', value: "www.google.hn"},
  {label: 'Hong Kong (www.google.com.hk)', value: "www.google.com.hk"},
  {label: 'Hungary (www.google.hu)', value: "www.google.hu"},
  {label: 'Iceland (www.google.is)', value: "www.google.is"},
  {label: 'India (www.google.co.in)', value: "www.google.co.in"},
  {label: 'Indonesia (www.google.co.id)', value: "www.google.co.id"},
  {label: 'Iraq (www.google.iq)', value: "www.google.iq"},
  {label: 'Ireland (www.google.ie)', value: "www.google.ie"},
  {label: 'Isle of Man (www.google.im)', value: "www.google.im"},
  {label: 'Israel (www.google.co.il)', value: "www.google.co.il"},
  {label: 'Italia (www.google.it)', value: "www.google.it"},
  {label: 'Ivory Coast (www.google.ci)', value: "www.google.ci"},
  {label: 'Jamaica (www.google.com.jm)', value: "www.google.com.jm"},
  {label: 'Japan (www.google.co.jp)', value: "www.google.co.jp"},
  {label: 'Jersey (www.google.je)', value: "www.google.je"},
  {label: 'Jordan (www.google.jo)', value: "www.google.jo"},
  {label: 'Kazakhstan (www.google.kz)', value: "www.google.kz"},
  {label: 'Kenya (www.google.co.ke)', value: "www.google.co.ke"},
  {label: 'Kiribati (www.google.ki)', value: "www.google.ki"},
  {label: 'Kuwait (www.google.com.kw)', value: "www.google.com.kw"},
  {label: 'Kyrgyzstan (www.google.kg)', value: "www.google.kg"},
  {label: 'Laos (www.google.la)', value: "www.google.la"},
  {label: 'Latvia (www.google.lv)', value: "www.google.lv"},
  {label: 'Lebanon (www.google.com.lb)', value: "www.google.com.lb"},
  {label: 'Lesotho (www.google.co.ls)', value: "www.google.co.ls"},
  {label: 'Libya (www.google.com.ly)', value: "www.google.com.ly"},
  {label: 'Liechtenstein (www.google.li)', value: "www.google.li"},
  {label: 'Lithuania (www.google.lt)', value: "www.google.lt"},
  {label: 'Luxembourg (www.google.lu)', value: "www.google.lu"},
  {label: 'Macedonia (www.google.mk)', value: "www.google.mk"},
  {label: 'Madagascar (www.google.mg)', value: "www.google.mg"},
  {label: 'Malawi (www.google.mw)', value: "www.google.mw"},
  {label: 'Malaysia (www.google.com.my)', value: "www.google.com.my"},
  {label: 'Maldives (www.google.mv)', value: "www.google.mv"},
  {label: 'Mali (www.google.ml)', value: "www.google.ml"},
  {label: 'Malta (www.google.com.mt)', value: "www.google.com.mt"},
  {label: 'Mauritius (www.google.mu)', value: "www.google.mu"},
  {label: 'Mexico (www.google.com.mx)', value: "www.google.com.mx"},
  {label: 'Moldova (www.google.md)', value: "www.google.md"},
  {label: 'Mongolia (www.google.mn)', value: "www.google.mn"},
  {label: 'Montenegro (www.google.me)', value: "www.google.me"},
  {label: 'Montserrat (www.google.ms)', value: "www.google.ms"},
  {label: 'Morocco (www.google.co.ma)', value: "www.google.co.ma"},
  {label: 'Mozambique (www.google.co.mz)', value: "www.google.co.mz"},
  {label: 'Namibia (www.google.com.na)', value: "www.google.com.na"},
  {label: 'Nauru (www.google.nr)', value: "www.google.nr"},
  {label: 'Nepal (www.google.com.np)', value: "www.google.com.np"},
  {label: 'Netherlands (www.google.nl)', value: "www.google.nl"},
  {label: 'New Zealand (www.google.co.nz)', value: "www.google.co.nz"},
  {label: 'Nicaragua (www.google.com.ni)', value: "www.google.com.ni"},
  {label: 'Niger (www.google.ne)', value: "www.google.ne"},
  {label: 'Nigeria (www.google.com.ng)', value: "www.google.com.ng"},
  {label: 'Niue (www.google.nu)', value: "www.google.nu"},
  {label: 'Norfolk Island (www.google.com.nf)', value: "www.google.com.nf"},
  {label: 'Norway (www.google.no)', value: "www.google.no"},
  {label: 'Oman (www.google.com.om)', value: "www.google.com.om"},
  {label: 'Pakistan (www.google.com.pk)', value: "www.google.com.pk"},
  {label: 'Palestine (www.google.ps)', value: "www.google.ps"},
  {label: 'Panama (www.google.com.pa)', value: "www.google.com.pa"},
  {label: 'Papua New Guinea (www.google.com.pg)', value: "www.google.com.pg"},
  {label: 'Paraguay (www.google.com.py)', value: "www.google.com.py"},
  {label: 'Peru (www.google.com.pe)', value: "www.google.com.pe"},
  {label: 'Philippines (www.google.com.ph)', value: "www.google.com.ph"},
  {label: 'Pitcairn Islands (www.google.pn)', value: "www.google.pn"},
  {label: 'Poland (www.google.pl)', value: "www.google.pl"},
  {label: 'Portugal (www.google.pt)', value: "www.google.pt"},
  {label: 'Puerto Rico (www.google.com.pr)', value: "www.google.com.pr"},
  {label: 'Qatar (www.google.com.qa)', value: "www.google.com.qa"},
  {label: 'Republic of the Congo (www.google.cg)', value: "www.google.cg"},
  {label: 'Romania (www.google.ro)', value: "www.google.ro"},
  {label: 'Russia (www.google.ru)', value: "www.google.ru"},
  {label: 'Rwanda (www.google.rw)', value: "www.google.rw"},
  {label: 'Saint Helena, Ascension and Tristan da Cunha (www.google.sh)', value: "www.google.sh"},
  {label: 'Saint Vincent and the Grenadines (www.google.com.vc)', value: "www.google.com.vc"},
  {label: 'Samoa (www.google.ws)', value: "www.google.ws"},
  {label: 'San Marino (www.google.sm)', value: "www.google.sm"},
  {label: 'Saudi Arabia (www.google.com.sa)', value: "www.google.com.sa"},
  {label: 'Senegal (www.google.sn)', value: "www.google.sn"},
  {label: 'Serbia (www.google.rs)', value: "www.google.rs"},
  {label: 'Seychelles (www.google.sc)', value: "www.google.sc"},
  {label: 'Sierra Leone (www.google.com.sl)', value: "www.google.com.sl"},
  {label: 'Singapore (www.google.com.sg)', value: "www.google.com.sg"},
  {label: 'Slovak (www.google.sk)', value: "www.google.sk"},
  {label: 'Slovenia (www.google.si)', value: "www.google.si"},
  {label: 'Solomon Islands (www.google.com.sb)', value: "www.google.com.sb"},
  {label: 'Somalia (www.google.so)', value: "www.google.so"},
  {label: 'South Africa (www.google.co.za)', value: "www.google.co.za"},
  {label: 'South Korea (www.google.co.kr)', value: "www.google.co.kr"},
  {label: 'Spain (www.google.es)', value: "www.google.es"},
  {label: 'Sri Lanka (www.google.lk)', value: "www.google.lk"},
  {label: 'Sweden (www.google.se)', value: "www.google.se"},
  {label: 'Switzerland (www.google.ch)', value: "www.google.ch"},
  {label: 'São Tomé and Príncipe (www.google.st)', value: "www.google.st"},
  {label: 'Taiwan (www.google.com.tw)', value: "www.google.com.tw"},
  {label: 'Tajikistan (www.google.com.tj)', value: "www.google.com.tj"},
  {label: 'Tanzania (www.google.co.tz)', value: "www.google.co.tz"},
  {label: 'Thailand (www.google.co.th)', value: "www.google.co.th"},
  {label: 'Timor-Leste (www.google.tl)', value: "www.google.tl"},
  {label: 'Togo (www.google.tg)', value: "www.google.tg"},
  {label: 'Tokelau (www.google.tk)', value: "www.google.tk"},
  {label: 'Tonga (www.google.to)', value: "www.google.to"},
  {label: 'Trinidad and Tobago (www.google.tt)', value: "www.google.tt"},
  {label: 'Tunisia (www.google.tn)', value: "www.google.tn"},
  {label: 'Turkey (www.google.com.tr)', value: "www.google.com.tr"},
  {label: 'Turkmenistan (www.google.tm)', value: "www.google.tm"},
  {label: 'Uganda (www.google.co.ug)', value: "www.google.co.ug"},
  {label: 'Ukraine (www.google.com.ua)', value: "www.google.com.ua"},
  {label: 'UAE (www.google.ae)', value: "www.google.ae"},
  {label: 'United Kingdom (www.google.co.uk)', value: "www.google.co.uk"},
  {label: 'Uruguay (www.google.com.uy)', value: "www.google.com.uy"},
  {label: 'Uzbekistan (www.google.co.uz)', value: "www.google.co.uz"},
  {label: 'Vanuatu (www.google.vu)', value: "www.google.vu"},
  {label: 'Venezuela (www.google.co.ve)', value: "www.google.co.ve"},
  {label: 'VietNam (www.google.com.vn)', value: "www.google.com.vn"},
  {label: 'Virgin Islands (www.google.co.vi)', value: "www.google.co.vi"},
  {label: 'Zambia (www.google.co.zm)', value: "www.google.co.zm"},
  {label: 'Zimbabwe (www.google.co.zw)', value: "www.google.co.zw"},
];

export default se_domains;
